import revive_payload_client_6hu93avqA0 from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_bufferutil@4.0.8_ioredis@5.4.1_magic_vrqtzvebfakhv6jhzdenwcdppu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_Q7UNoUq2bh from "/app/node_modules/.pnpm/vuetify-nuxt-module@0.18.3_magicast@0.3.5_rollup@4.27.3_typescript@5.7.2_vite@5.4.11_@types+n_2y46l43tzxqrncf5xyvvpi2nbe/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_GyArDCk12E from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_bufferutil@4.0.8_ioredis@5.4.1_magic_vrqtzvebfakhv6jhzdenwcdppu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_QwRKvlUd3q from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_bufferutil@4.0.8_ioredis@5.4.1_magic_vrqtzvebfakhv6jhzdenwcdppu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_a8JmcR9IIS from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_bufferutil@4.0.8_ioredis@5.4.1_magic_vrqtzvebfakhv6jhzdenwcdppu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_5OYthCrG8g from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_bufferutil@4.0.8_ioredis@5.4.1_magic_vrqtzvebfakhv6jhzdenwcdppu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Mb56UlpFKm from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_bufferutil@4.0.8_ioredis@5.4.1_magic_vrqtzvebfakhv6jhzdenwcdppu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_9B8NVikNVh from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_bufferutil@4.0.8_ioredis@5.4.1_magic_vrqtzvebfakhv6jhzdenwcdppu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Mv1plkYg3W from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.27.3_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_iGaJDHTn2D from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_bufferutil@4.0.8_ioredis@5.4.1_magic_vrqtzvebfakhv6jhzdenwcdppu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vuetify_no_client_hints_OlNbpRaO8Y from "/app/node_modules/.pnpm/vuetify-nuxt-module@0.18.3_magicast@0.3.5_rollup@4.27.3_typescript@5.7.2_vite@5.4.11_@types+n_2y46l43tzxqrncf5xyvvpi2nbe/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import plugin_wy0B721ODc from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_4M2vu8gxXm from "/app/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.27.3_typesc_rzjzlm2rkollv65qlwlfubjqeu/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import cloudflare_client_l7hHizcRfX from "/app/plugins/cloudflare.client.ts";
import matomo_client_Ed2EqINkhd from "/app/plugins/matomo.client.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/app/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_6hu93avqA0,
  vuetify_icons_Q7UNoUq2bh,
  unhead_GyArDCk12E,
  router_QwRKvlUd3q,
  payload_client_a8JmcR9IIS,
  navigation_repaint_client_5OYthCrG8g,
  check_outdated_build_client_Mb56UlpFKm,
  chunk_reload_client_9B8NVikNVh,
  plugin_vue3_Mv1plkYg3W,
  components_plugin_KR1HBZs4kY,
  prefetch_client_iGaJDHTn2D,
  vuetify_no_client_hints_OlNbpRaO8Y,
  plugin_wy0B721ODc,
  plugin_4M2vu8gxXm,
  cloudflare_client_l7hHizcRfX,
  matomo_client_Ed2EqINkhd,
  sentry_client_shVUlIjFLk,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]